<template>
  <div>
  <kendo-datasource
            :ref="'formDataSource'"
            :data="formDataSource.data">
          </kendo-datasource>
    <ConfigurationHeaderContainer title="Forms for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Forms</div>
      <div class="form-entry-input">
        <div class="forms">
          <div>
              <div style="margin-left: 800px;"><button class="k-button" @click="addForm">Add Form</button></div>
              <div style="margin-left: 140px; border: solid lightgray 1px; width: 445px; height: 35px;">Stages where the form will be available for data extraction</div>
              <kendo-grid
                :id="'selectedFormGrid'"
                :ref="'selectedFormGrid'"
                :data-source-ref="'formDataSource'"
                :scrollable="scrollable"
                :selectable="selectable"
                :columns="columns"
                :editable="editable"
                style="width: 800px;">
              </kendo-grid>
          </div>
        </div>
      </div>
      <div class="form-entry-container" v-if="this.$store.state.isDoubleDataEntry">
      <div class="form-entry-label">Restrict form data extraction if citations has been fully reviewed?</div>
      <div class="form-entry-input">
        <input type="radio" name="allowvalidation1" id="allowvalidation" :checked="true">
        <label for="single" >No</label>
        <input type="radio" name="allowvalidation1" id="allowvalidation">
        <label for="single">Yes</label>
      </div>
    </div>
    </div>
    <kendo-window :ref="'formEditWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :position="windowPosition"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <div style="display:flex; flex-direction: row; justify-content: flex-end;">
        <button class="k-button" @click="onCancelEditWindow">Save Form</button>
        <button class="k-button" @click="onCancelEditWindow">Cancel</button>
      </div>
      <FormEntryContainer title="Form Name" type="text" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
      <FormEntryContainer title="Form Description" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
      <FormEntryContainer title="Form Tags" type="formTagEdit" labeltype="none"></FormEntryContainer>
      <SurveyEntryContainer title="Form Questions" type="surveyEditor" labeltype="none"></SurveyEntryContainer>
    </kendo-window>
    <kendo-window :ref="'formAddWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <div v-if="addFormWizardScreen === 1">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <div style="width: 500px;">
            <FormEntryContainer title="Search from Template Tags" type="multiselect" autowidth=true size="small" :datasource="tagDataSource" textfield="TagName" labeltype="none"></FormEntryContainer>
            <FormEntryContainer title="Search from Template Questions (comma separated)" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
            <button class="k-button">Search</button>
            <FormEntryContainer title="Template description" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
          </div>
          <div  style="width: 700px;">
            <FormEntryContainer title="Search results for Form Templates" :datasource="formTemplatesDataSource" type="treelist" labeltype="none"></FormEntryContainer>
            <FormEntryContainer title="Template Tags" type="formTagReadOnly" labeltype="none"></FormEntryContainer>
          </div>
          <div style="width: 1300px;">
            <!--  -->
          </div>
        </div>
        <SurveyEntryContainer title="Template Questions" type="surveyViewer" labeltype="none"></SurveyEntryContainer>
        <div style="display:flex; flex-direction: row; justify-content: flex-end;">
          <button class="k-button" @click="onSelectEditWindow">Select and Edit Form</button>
          <button class="k-button" @click="onCancelAddWindow">Exit</button>
        </div>
      </div>
      <div v-if="addFormWizardScreen === 2">
        <SurveyEntryContainer title="Template Questions" type="surveyViewer" labeltype="none"></SurveyEntryContainer>
        <div style="display:flex; flex-direction: row; justify-content: flex-end;">
          <button class="k-button" @click="onSaveFormAndExitWindow">Save Form and Exit</button>
          <button class="k-button" @click="onCancelAddWindow">Exit</button>
        </div>
      </div>
    </kendo-window>
    <kendo-window :ref="'formRemoveWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <div>
        <SurveyEntryContainer title="Form Questions" type="surveyEditor"></SurveyEntryContainer>
        Are you sure you want to remove this form?
      </div>
      <div style="display:flex; flex-direction: row; justify-content: flex-end;">
        <button class="k-button">Remove</button>
        <button class="k-button">Cancel</button>
      </div>
    </kendo-window>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import SurveyEntryContainer from '@/containers/SurveyEntryContainerV2'
import StageDataSource from '@/assets/data/Stage.json'
import FormDataSource from '@/assets/data/Form.json'
import TagDataSource from '@/assets/data/Tag.json'
import SurveyTemplateOnePage from '@/assets/data/SurveyTemplateOnePage.json'
import SurveyTemplateTwoPage from '@/assets/data/SurveyTemplateTwoPage.json'
import SurveyTemplateCompleteEditor from '@/assets/data/SurveyTemplateCompleteEditor.json'
import FormStageDataSource from '@/assets/data/FormStage.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'stages',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer,
    SurveyEntryContainer
  },
  data () {
    return {
      selectable: true,
      sortable: true,
      pageable: false,
      groupable: false,
      scrollable: false,
      filterable: false,
      addFormWizardScreen: 1,
      formDataSource: FormDataSource,
      tagDataSource: TagDataSource,
      columns: this.generateColumns(),
      editable: {
        mode: 'popup',
        window: {
          animation: false,
          open: this.editForm
        }
      },
      editableSurvey: {
        mode: 'popup',
        window: {
          animation: false,
          open: this.editForm
        }
      },
      windowTitle: '',
      windowWidth: '1550px',
      windowHeight: '700px',
      windowPosition: { left: 0 },
      windowVisible: false,
      component: 'FormEdit',
      surveyEditComponent: 'SurveyEdit',
      parentvariable: '',
      surveyTemplateOnePage: SurveyTemplateOnePage,
      surveyTemplateTwoPage: SurveyTemplateTwoPage,
      surveyTemplateCompleteEditor: SurveyTemplateCompleteEditor,
      surveyCreator: {},
      formTemplatesDataSource: {
        data: [
          { NodeId: 1, NodeName: 'Template group 1' },
          { NodeId: 2, NodeName: 'Template 1', ReportsTo: 1 },
          { NodeId: 3, NodeName: 'Template 2', ReportsTo: 1 },
          { NodeId: 4, NodeName: 'Template 3', ReportsTo: 1 },
          { NodeId: 5, NodeName: 'Template group 2' },
          { NodeId: 6, NodeName: 'Template 1', ReportsTo: 5 },
          { NodeId: 7, NodeName: 'Template 2', ReportsTo: 5 }
        ],
        schema: {
          model: {
            id: 'NodeId',
            parentId: 'ReportsTo',
            expanded: true,
            batch: true,
            fields: {
              NodeId: { type: 'number', editable: false, nullable: false },
              NodeName: { validation: { required: true } },
              ReportsTo: { nullable: true, type: 'number' }
            }
          }
        },
        columns: [
          { field: 'NodeName', title: ' ', width: 100, expandable: true }
        ]
      }
    }
  },
  methods: {
    generateColumns: function () {
      var columnsObject = []
      columnsObject.push({ field: 'FormName', title: 'Form', width: '140px' })
      StageDataSource.data.forEach(function (value) {
        if (value.StageName !== 'Study Mapping') {
          columnsObject.push({ field: 'StageIdentity_' + value.StageIdentity, title: value.StageName, template: '<input type="checkbox" id="mm1_#: FormIdentity #_' + value.StageIdentity + '">', width: '9fr' })
        }
      })
      // TODO: Uncomment to add the other edit
      columnsObject.push(
        {
          command: [
            // { name: 'Edit', className: 'k-state-disabled', click: this.editForm, width: '40px' },
            { name: 'Edit', attributes: { class: 'k-button' }, click: this.editForm, width: '40px' },
            { name: 'Remove', attributes: { class: 'k-button' }, click: this.removeForm, width: '40px' }
          ]
        }
      )
      return columnsObject
    },
    addForm: function (e) {
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      this.windowTitle = 'Add Form'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      this.windowPosition = { top: 0, left: 40 }
      windowWidget.close()
      windowWidget.open().center()
    },
    onSelectEditWindow: function (e) {
      this.addFormWizardScreen = 2
    },
    onSaveFormAndExitWindow: function () {
      this.addFormWizardScreen = 1
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      windowWidget.close()
      windowWidget.open().center()
    },
    onCancelAddWindow: function () {
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      windowWidget.close()
    },
    onCancelEditWindow: function () {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      windowWidget.close()
    },
    editForm: function (e) {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      this.windowTitle = 'Edit Form'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.open().center()
    },
    removeForm: function (e) {
      var windowWidget = this.$refs.formRemoveWindow.kendoWidget()
      this.windowTitle = 'Remove Form'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.open().center()
    },
    addPage: function () {
      alert('addPage Event triggered')
    },
    addQuestion: function () {
      alert('addQuestion Event triggered')
    },
    deleteQuestion: function (editor, options) {
      alert('deleteQuestion Event triggered - deleting "' + options.element.name + '"')
    },
    propertyValueChanging: function (sender, options) {
      // alert('propertyValueChanging Event - changing "' + options.propertyName + '" from "' + options.value + '" to "' + options.newValue + '"')
      console.log('propertyValueChanging Event - changing "' + options.propertyName + '" from "' + options.value + '" to "' + options.newValue + '"')
    },
    getPropertyReadOnly: function (sender, options) {
      console.log(options.propertyName)
      if (options.propertyName === 'isRequired') {
        options.readOnly = true
      }
      if (options.propertyName === 'visible') {
        options.readOnly = true
      }
    },
    surveySave: function () {
      var savedSurvey = this.surveyCreator.JSON
      this.$store.state.surveyDesignerDummyTest = this.surveyCreator.JSON
      console.log(savedSurvey.pages[0])
      alert('Survey Saved!!!')
    }
  },
  created: function () {
    emitter.on('closeWindow', () => {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      windowWidget.close()
    })
    emitter.on('changeWindowTitle', (title) => {
      this.windowTitle = title
    })
  },
  mounted: function () {
    FormStageDataSource.data.forEach(function (value) {
      document.getElementById('mm1_' + value.FormIdentity + '_' + value.StageIdentity).setAttribute('checked', '')
    })
  }
}
</script>
