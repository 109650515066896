<template>
  <div>
    <ConfigurationHeaderContainer title="Studies for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Define Studies</div>
      <div class="form-entry-input">
        <div class="studies">
          <div class="studies-item">
            <kendo-datasource
              :ref="'studyDataSource'"
              :data="studyDataSource.data">
            </kendo-datasource>
            <kendo-grid
                :id="'studyGrid'"
                :ref="'studyGrid'"
                :data-source-ref="'studyDataSource'"
                :selectable="selectable"
                :columns="columns">
              </kendo-grid>
            <div>
              <a href="#" @click="addStudy()">Add</a>
              <a href="#">Edit</a>
              <a href="#">Delete</a>
            </div>
            <kendo-window
              :ref="'studyWindow'"
              :width="'500px'"
              :visible="false"
              :modal="true"
              style="display:none">
                  <FormEntryContainer title="study Name" type="text" size="10"></FormEntryContainer>
                  <b-button-group class="action-buttons-nav">
                  <b-button type="submit" variant="primary" size="sm" style="width: 80px" @click="addStudy">Add</b-button>
                  &nbsp;
                  <b-button type="submit" variant="primary" size="sm" style="width: 80px">Cancel</b-button>
              </b-button-group>
            </kendo-window>
          </div>
        </div>
      </div>
      <div class="form-entry-container" v-if="this.$store.state.isDoubleDataEntry">
        <div class="form-entry-label">Restrict study mapping until citation has been fully reviewed?</div>
          <div class="form-entry-input">
            <input type="radio" name="allowvalidation2" id="allowvalidation" :checked="true">
            <label for="single" >No</label>
            <input type="radio" name="allowvalidation2" id="allowvalidation">
            <label for="single">Yes</label>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import StudyDataSource from '@/assets/data/Study.json'

export default {
  name: 'studies',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  methods: {
    studyDataBound: function () {
      this.$store.state.isLoading = false
    },
    addStudy: function () {
      var windowWidget = this.$refs.studyWindow.kendoWidget()
      windowWidget
        .title('Add Study')
        .open()
    }
  },
  data () {
    return {
      selectable: true,
      studyDataSource: StudyDataSource,
      columns: [
        { field: 'StudyID', title: 'Study ID', headerAttributes: { class: 'grid-header-light' } },
        { field: 'StudyAcronym', title: 'Acronym', headerAttributes: { class: 'grid-header-light' } },
        { field: 'StudyDescription', title: 'Description', headerAttributes: { class: 'grid-header-light' } }
      ]
    }
  }
}
</script>
