<template>
    <div class="configuration-home">
        <!-- <div style="display: flex; flex-direction: row; justify-content: space-around; width: 400px;">
          <button>Edit</button>
          <button>Submit changes</button>
          <button>Cancel changes</button>
        </div> -->
        <!-- <div style="display: flex; flex-direction: row; justify-content: space-around; width: 400px;">
          <button @click="viewAll">View All</button>
        </div> -->
        <!-- <div style="display: flex; flex-direction: row; justify-content: flex-end;" @click="expandCollapseAll()">Collapse All</div> -->
        <h4 v-show="this.$store.state.configurationViewAll">Explanatory text TBD new</h4>
        <!-- <button class="k-button" @click="downloadConfigurationTemplate">Download configuration template</button>  -->
        <!-- <button class="k-button" @click="importConfigurationTemplate">Import configuration</button>  -->
        <ConfigurationContainer title="General Info for Project X" component="GeneralInfo" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="PICOS criteria for Project X" component="PicosCriteria" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Stages for Project X" component="Stages" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Studies for Project X" component="Studies" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Forms for Project X" component="Forms" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Users for Project X" component="Users" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Exclusion Reasons for Project X" component="ExclusionReasons" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Keywords/Tags for Project X" component="KeywordsTags" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <ConfigurationContainer title="Preferences for Project X" component="Preferences" :collapsed="allIsCollapsed" v-show="true"></ConfigurationContainer>
        <kendo-window
          :ref="'downloadTemplateWindow'"
          :width="'1000px'"
          :visible="false"
          :modal="true"
          style="display:none">
          <FormEntryContainer title="Populate from an existing project" type="radioYesNo"></FormEntryContainer>
          <FormEntryContainer
            title="Project"
            type="dropdown"
            :datasource="this.projectDataSource"
            :valuefield="'ProjectIdentity'"
            :textfield="'ProjectName'"></FormEntryContainer>
          <FormEntryContainer title="Select folder" type="downloadFolder"></FormEntryContainer>
          <button class="k-button">Ok</button>
          <button class="k-button">Cancel</button>
        </kendo-window>
        <kendo-window
          :ref="'importConfigurationWindow'"
          :width="'1000px'"
          :visible="false"
          :modal="true"
          style="display:none">
          <FormEntryContainer title="Select file" type="file"></FormEntryContainer>
          <button class="k-button">Ok</button>
          <button class="k-button">Cancel</button>
        </kendo-window>
    </div>
</template>

<script>
import ConfigurationContainer from '@/containers/ConfigurationContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import ProjectDataSource from '@/assets/data/Project.json'

export default {
  name: 'configuration-of-projects-home',
  components: {
    FormEntryContainer,
    ConfigurationContainer
  },
  methods: {
    viewAll: function () {
      this.$store.state.navigation[1].actions[0].visible = true
      this.$store.state.navigation[1].actions[1].visible = true
      this.$store.state.navigation[1].actions[2].visible = true
      this.$store.state.navigation[1].actions[3].visible = true
      this.$store.state.navigation[1].actions[4].visible = true
      this.$store.state.navigation[1].actions[5].visible = true
      this.$store.state.navigation[1].actions[6].visible = true
      this.$store.state.navigation[1].actions[7].visible = true
      this.$store.state.navigation[1].actions[8].visible = true
    },
    downloadConfigurationTemplate: function () {
      var windowWidget = this.$refs.downloadTemplateWindow.kendoWidget()
      windowWidget
        .title('Download configuration template')
        .open()
        .center()
    },
    importConfigurationTemplate: function () {
      var windowWidget = this.$refs.importConfigurationWindow.kendoWidget()
      windowWidget
        .title('Import configuration template')
        .open()
        .center()
    }
  },
  data () {
    return {
      allIsCollapsed: false,
      projectDataSource: ProjectDataSource
    }
  }
}
</script>
