<template>
  <div class="configuration-container" :id="component">
    <div class="configuration-header">
        <div>
        <!-- <h3>View All</h3> -->
        <!-- <h3 class="configuration-header-expand-collapse" @click="expandCollapse()">
           {{ this.expandCollapsedText }}
        </h3> -->
          <!-- <button class="k-button" v-show="this.$store.state.selectedAction !== 'view-all'">View All</button> -->
          <button class="k-button" v-show="this.$store.state.selectedAction === 'configuration-view-all'" @click="onEdit()">Edit</button>
          <!-- <button class="k-button" v-show="this.$store.state.selectedAction !== 'configuration-view-all'" @click="save">Save</button>
          <button class="k-button" v-show="this.$store.state.selectedAction !== 'configuration-view-all'" @click="cancel">Cancel</button> -->
        </div>
    </div>
    <component class="component" :is="component" style="height: calc(100% - 20px)" v-if="!this.isCollapsed"></component>
  </div>
</template>

<script>
import GeneralInfo from '@/views/secure/configuration/GeneralInfo'
import PicosCriteria from '@/views/secure/configuration/PicosCriteria'
import Users from '@/views/secure/configuration/Users'
import Stages from '@/views/secure/configuration/Stages'
import Studies from '@/views/secure/configuration/Studies'
import Forms from '@/views/secure/configuration/Forms'
import ExclusionReasons from '@/views/secure/configuration/ExclusionReasons'
import KeywordsTags from '@/views/secure/configuration/KeywordsTags'
import Preferences from '@/views/secure/configuration/Preferences'

export default {
  props: ['title', 'detach', 'component', 'collapsed'],
  components: {
    GeneralInfo,
    PicosCriteria,
    Users,
    Stages,
    Studies,
    Forms,
    ExclusionReasons,
    KeywordsTags,
    Preferences
  },
  methods: {
    onEdit: function () {
      alert(this.title)
    },
    expandCollapse: function () {
      this.isCollapsed = !this.isCollapsed
      if (this.isCollapsed) {
        this.expandCollapsedText = 'Expand'
      } else {
        this.expandCollapsedText = 'Collapse'
      }
    },
    save: function () {
      if (confirm('If you Click Ok, configuration changes will be made on this item, else click on Cancel to return to current configuration option')) {
        this.$store.state.selectedAction = 'view-all'
        this.$store.state.configurationViewAll = true
        this.$store.state.navigation[1].actions[0].visible = true
        this.$store.state.navigation[1].actions[1].visible = true
        this.$store.state.navigation[1].actions[2].visible = true
        this.$store.state.navigation[1].actions[3].visible = true
        this.$store.state.navigation[1].actions[4].visible = true
        this.$store.state.navigation[1].actions[5].visible = true
        this.$store.state.navigation[1].actions[6].visible = true
        this.$store.state.navigation[1].actions[7].visible = true
      } else {
        this.$store.state.configurationViewAll = false
      }
    },
    cancel: function () {
      if (confirm('If you Click Ok, configuration changes will be made on this item, else click on Cancel to return to View All and ignore changes')) {
        alert('cancelling')
        this.$store.state.selectedAction = 'view-all'
        this.$store.state.configurationViewAll = true
        this.$store.state.navigation[1].actions[0].visible = true
        this.$store.state.navigation[1].actions[1].visible = true
        this.$store.state.navigation[1].actions[2].visible = true
        this.$store.state.navigation[1].actions[3].visible = true
        this.$store.state.navigation[1].actions[4].visible = true
        this.$store.state.navigation[1].actions[5].visible = true
        this.$store.state.navigation[1].actions[6].visible = true
        this.$store.state.navigation[1].actions[7].visible = true
        this.$store.state.navigation[1].actions[8].visible = true
      } else {
        this.$store.state.configurationViewAll = false
      }
    }
  },
  data () {
    return {
      expandCollapsedText: 'Collapse',
      isCollapsed: false
    }
  },
  created: function () {
    if (this.collapsed) {
      this.isCollapsed = true
      this.expandCollapsedText = 'Expand'
    } else {
      this.isCollapsed = false
      this.expandCollapsedText = 'Collapse'
    }
  }
}
</script>

<style scoped>

</style>
